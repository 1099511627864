.footer{
    display: flex; 
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
}
.footer-logo{
    width: 25px;
}


@media only screen and (max-width: 1024px) {

    .footer{
       padding: 20px;
    }
}