.agency-text{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 4em;
}
.agency-title{
  font-size: 6em;
  font-weight: 400;
}
.agency-para{
 width: 50%;
  font-size: 27px;
  line-height: 2.5rem;
  color: var(--color-text);
}
.main-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;  
 gap: 2em;
 margin-top: 2.5em;
}
.container{
  width: 45%;
  text-decoration: none;
 
  
}
.img-container{
  
  width: 100%;
  height: 35em; 
  transition: all .4s ease-in;
 

}
.img{
  width: 100%;
  border-radius: 10px;
  height: 100%;
  background-position: center;
  object-fit: cover;
 
}
.img:hover{
  opacity: 0.9;
 }
.text-container{
  display: flex;
  flex-direction: column;
  
  color: white;
  gap: .1em;
  
}
.img-text{
  display: flex;
  align-items: center;
  gap: .6em;
  text-decoration: none;
  color: var(--color-text);
  margin-left: .5em;

}
.img-logo{
  width: 20px;
 opacity: 0.7;
}
.img-title{
font-family: "Epilogue", serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 1.4em;
    color: #ffffff;
   
margin: .5em;

    
  }

  .a3d{
    color: var(--color-text);
    font-size: 1.3em;
    font-weight: 500;
   }
  
   .img-container:hover{
    opacity: 0.6;
  }
  

  @media only screen and (max-width: 1024px) {

    .agency-text{
    flex-direction: column;
    padding: 1.5em;
   
    }
    .agency-title{
      font-size: 3.5em;
      align-items: flex-start;
      margin-right: auto;
     
    }
    .agency-para{
     width: 100%;
       font-size: 20px;
       line-height: 1.5em;
       align-items: flex-start;
       margin-top: 20px;
      
      
       
     }
     .main-container{
      flex-direction: column;
      align-items: center;
     
    
    }
    .container{
      width: 90%;
      text-decoration: none;
     
      
    }
    .img-container{
  
      width: 100%;
      height: 20em; 
      transition: all .4s ease-in;
     
     
    
    }
  
   

   
  }
 
 



