.navBar {
  display: flex;
  justify-content: space-between;
  padding: 1.5em;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.7);
  position: sticky;
  top: 0px;
  will-change: transform;
  z-index: 1;
}

.navBar-logo {
  width: 2em;
}

.email-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.email-arrow {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.email-logo:hover {
  opacity: 0.5;
}
p {
  font-size: 1.2em;
}

.logo1 {
  width: 2em;
  height: auto;
  transition: all 1s ease-in-out;
}

@media only screen and (max-width: 1024px) {
  .navBar {
    padding: 30px 10px;
  }
}
