.project {
  min-height: 100%;
  width: 100%;
}

.project-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 50px 20px;
}

.project-title {
  color: var(--white);
  font-family: "Epilogue", serif;
  font-size: 6em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 0;
}

.project-text-header {
  place-content: center flex-start;
  align-items: center;
  display: flex;
  gap: 0.5em;
  height: min-content;
  color: var(--color-text);
}

.project-text-title {
  font-size: 1.2em;
}
.project-text-branding {
  font-size: 1.2em;
  font-weight: 400;
}
.text3d {
  font-size: 1.2em;
  font-weight: 400;
}

.project-header-text {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.project-text-logo-1,
.project-text-logo-2 {
  width: 20px;
  opacity: 0.6;
}
.project-text {
  font-family: "Epilogue", serif;
  font-size: 1.55em;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  color: var(--color-text);
  max-width: 560px;
}

.home-img {
  width: 100%;
}

.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 40%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.project-2-container {
  display: flex;
  gap: 15%;
  width: 100%;
  padding: 5em 6em;
}
.project-sticky-container {
  display: flex;
  flex: 1 0 0em;
  flex-flow: column nowrap;
  gap: 2em;
  height: min-content;
  left: 0;

  position: sticky;
  top: 6em;
  will-change: transform;
  z-index: 1;
}

.project-sticky-title {
  color: #fff;
  font-family: "Epilogue", serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.4em;
}
.project-sticky-text-1 {
  color: var(--color-text);
  font-family: "Epilogue" sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;
  text-align: start;
  text-decoration: none;
  text-transform: none;
}

.project-sticky-text-2 {
  color: var(--color-text);
  font-family: "Epilogue" sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;
  text-align: start;
  text-decoration: none;
  text-transform: none;
}

.home-container-imgs {
  place-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: 1 0 0px;
  flex-flow: column nowrap;
  gap: 2em;

  width: 100%;
  height: auto;
}
.home-img1 {
  border-radius: 0.5em;
  flex: 1 0 0px;

  /* overflow: hidden; */

  width: 100%;
  will-change: transform;
  object-fit: cover;
}
.home-img2 {
  border-radius: 0.5em;
  flex: 1 0 0px;

  /* overflow: hidden; */

  width: 100%;
  will-change: transform;
  object-fit: cover;
}

.home-img3 {
  border-radius: 0.5em;
  flex: 1 0 0px;

  width: 100%;
  will-change: transform;
  object-fit: cover;
}
.CEO-container {
  place-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-flow: row nowrap;
  gap: 1em;
  padding: 5rem;
  width: 100%;
}
.CEO-title {
  align-items: flex-start;
  display: flex;
  flex: 0.7 0;
  flex-flow: column nowrap;
  gap: 2em;
  place-content: flex-start;
}

.CEO-title p {
  color: var(--white);
  font-family: "Epilogue", serif;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.5em;
}

.CEO-name {
  place-content: center flex-start;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  gap: 1em;
}
.profile {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}
.CEO-Name {
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
}
.Ceo-text {
  font-family: "Epilogue", serif;
  font-style: normal;
  font-weight: 400;
  color: var(--color-text);
  font-size: 1.2em;
}

.photo {
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  flex: 0 0 auto;
  height: 4em;

  margin: 0%;

  width: 4.2em;

  object-fit: cover;
}

.container-home1 {
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  padding: 5em 7em;
  width: 100%;
  gap: 4em;
}
.container-1 {
  font-family: "Epilogue", serif;
  color: var(--white);
  font-size: 4em;
  font-weight: 400;
  width: 50%;
  line-height: 1.3em;
}
.container-home2 {
  place-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: 1 0 0px;
  flex-flow: column nowrap;
  gap: 2em;
  height: min-content;
  width: 90%;
}

.container-2 {
  color: var(--color-text);
  font-family: "Epilogue", serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3em;
}
.container-3 {
  color: var(--color-text);
  font-family: "Epilogue", serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 2em;
}

.home-container-row {
  display: flex;
  width: 100%;
  padding-top: 1em;
}
.home-img4 {
  aspect-ratio: 0.750469 / 1;
  flex: 1 0 0px;

  overflow: hidden;
}

.home-img5 {
  aspect-ratio: 0.750469 / 1;
  flex: 1 0 0px;

  overflow: hidden;
}

.home-img6 {
  aspect-ratio: 0.750469 / 1;
  flex: 1 0 0px;

  overflow: hidden;
}

.Home-social {
  padding: 4em;
}
.home-clients {
  margin-right: 10em;
  margin-left: 3em;
}

.home-Footer {
  padding-top: 2em;
}

@media only screen and (max-width: 1024px) {
  .project-container {
    padding: 1.5em;
    flex-direction: column;
  }
  .project-title {
    font-size: 3.5em;
    margin-top: 1.3em;
  }
  .project-header-text {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 3em;
  }
  .project-text {
    font-size: 1.2em;
  }

  .home-img {
    /* padding: 1em; */
    width: 100%;
    margin-top: 70px;

    flex-direction: column;
  }
  .project-2-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 1.5em;
  }

  .project-sticky-title {
    max-width: 350px;
    font-size: 30px;
    letter-spacing: 0;
    margin-top: 70px;
  }
  .project-sticky-text-1 {
    font-size: 1.2em;
    line-height: 1.5em;
  }
  .project-sticky-text-2 {
    font-size: 1.2em;
    line-height: 1.5em;
  }

  .home-container-imgs {
    gap: 4em;
  }
  .project-sticky-container {
    position: static;
    top: 0;
  }
  .CEO-container {
    padding: 1.5em;
    flex-direction: column;
    margin-top: 10%;
  }

  .CEO-title p {
    font-size: 16px;
  }

  .CEO-Name {
    font-size: 16px;
  }
  .Ceo-text {
    font-size: 17px;
  }
  .container-home1 {
    padding: 1.5em;
    flex-direction: column;
  }
  .container-1 {
    font-size: 30px;
    line-height: 1.3em;
    letter-spacing: 0;
    width: 100%;
    margin-top: 2em;
  }
  .container-2 {
    font-size: 1.2em;
    letter-spacing: -0.3px;

    margin-top: 1em;

    line-height: 1.5em;
  }

  .container-3 {
    font-size: 1.2em;

    letter-spacing: -0.3px;

    font-size: 1.2em;
    line-height: 1.5em;
  }

  .home-container-row {
    /* padding: 2em; */
    flex-direction: column;
    width: 100%;
    margin-top: 5em;
  }

  .home-clients {
    margin: 0;
  }
  .home-clients h1{
    padding-left: 0!important;
  }
}
