.absolute-center{
    display: flex;
    justify-content: center;
    align-items: center;
}



.cur-po{
    cursor: pointer;
}