.careers {
  display: flex;
  padding: 10em 3.8em;
  justify-content: space-between;
}

.career-title {
  font-size: 4em;
  font-weight: 400;
}
.careers-link {
  display: flex;
  justify-content: space-between;
  width: 45%;
}
.careers-left-link {
  list-style: none;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  transition: all 2s ease-in;
}
.careers-right-link {
  list-style: none;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.6em;
  color: var(--color-text);
  transition: all 2s ease-in;
}
.careers-right-link li:hover {
  opacity: 0.1;
  color: var(--color-text);
}
.careers-left-link li:hover {
  opacity: 0.1;
  color: var(--color-text);
}
@media only screen and (max-width: 1024px) {
  .careers {
    flex-direction: column;
    padding: 1.5em;
    margin-top: 2em;
  }
  .career-title {
    font-size: 2.2em;
    margin-bottom: 1em;
  }
  .careers-link {
    width: 100%;
  }
  .careers-left-link li {
    font-size: 75%;
  }
  .careers-right-link li {
    font-size: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
