@import url('./common/styles/commonClasses.css');
@import url('./common/styles/variables.css');

body{
  background-color: var(--background-color);
  color: var(--white);
  


}

 /* ::-webkit-scrollbar{
  display: none; */
  
  





