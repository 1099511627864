.social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10em 3em;
}
.social-email {
  display: flex;
  gap: 5px;
  color: white;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}
.email {
  font-weight: 400;
  font-size: 2.5em;
  font-weight: 400;
  transition: all 1s ease-in-out;
  cursor: pointer;
}
.social-email-logo {
  width: 33px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
.social-email:hover {
  opacity: 0.4;
}

.social-links {
  font-family: "Epilogue", serif;
  font-size: 1.5em;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 45%;
  padding-left: 40px;
  gap: 2em 10em;
  flex-flow: column wrap;
}

.social-link {
  color: white;
  text-align: start;
  text-decoration: none;
}

.social-link :hover {
  color: var(--color-text);
  opacity: 0.7;
}

@media only screen and (max-width: 1024px) {
  .social {
    align-items: flex-start;
    flex-direction: column;
    margin: 10em 1.3em;
  }
  .email {
    font-size: 19px;
  }

  .social-email-logo {
    width: 1.5em;
  }
  .social-links {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    padding-left: 0px;
  }
  
  .Home-social {
    padding: 1px 1px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
