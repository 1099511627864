.services {
    display: flex;
    justify-content: space-between;
    padding: 17em 3.4em;
    align-items: flex-start;
}
.services-title {
  font-size: 4em;
  font-weight: 400;
  position: sticky;
  top: 2em;
}
.services-text-container{
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 3em;    
}
.services-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2em;
}
.services-logo{
    width: 1.5em;
}
.services-text-title{
    font-size: 1.3em;
    font-weight: 400;
}
.services-text{
    font-size: 20px;
   
    line-height: 1.5em;
    font-weight: 400;
    color: var(--color-text);
}

@media only screen and (max-width: 1024px) {
    .services{
        padding: 1.5em;
        flex-direction: column;
    }
    .services-title {
        font-size: 2.2em;
        font-weight: 400;
       position: relative;
        top: 0em;
        margin-bottom: 1.5em;
      }
      .services-text-container{
       width: 100%;
       
        flex-direction: column;
        gap: 2em;     
    }
    .services-container{
        display: flex;
        flex-direction: column;
       gap: 1.5em;
    }
    .services-logo{
        width: 1.4em;
    }
    .services-text-title{
        font-size: 1.2em;
       
    }
    .services-text{
        
        font-size: 1.2em;
       
  
      
       color: var(--color-text);
     
     
    
    }
     
}
