.about {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.4em 4em;
 
}
.about-title {
  font-size: 4.5em;
  font-weight: 400;
  position: sticky;
  top: 2em;
 
 
 
  
}
.about-container {
 
  width: 45%;
  height: 55%;
  display: flex;
  flex-direction: column;
  gap: 2em; 
 
}
.about-text-one{
  width: 95%;

  
  
  font-size: 20px;
    line-height: 2.1rem;
    font-weight: 400;
    color: var(--color-text);
}
.about-text-2{
  width: 95%;
  font-size: 20px;
    line-height: 2.1rem;
    font-weight: 400;
    color: var(--color-text);
}
.about-img-container {
  width: 20em;
  
 
}

.about-main-img {
  width: 35em;
 border-top-right-radius: 5px;
 border-top-left-radius: 5px;
 
  object-fit: cover;
 
}
.about-img-1 {
  width: 16.7em;
  border-bottom-left-radius: .9em;
  
 
 
}
.about-img-2 {
  width: 16.7em;
  border-bottom-right-radius: .9em;
 
 
 

}
.about-second-img {
  display: flex;
 gap: 1.5em;

 /* padding-top: 2em; */
 margin-top: 2em;
 
 
}



@media only screen and (max-width: 1024px) {
  .about{
      padding: 8em 1.5em;
      flex-direction: column;
      
  }
  .about-title {
      font-size: 2.2em;
      font-weight: 400;
     position: relative;
      top: 0em;
      margin-bottom: 1em;
     
    }
    .about-container {
     width: 100%;
   
     
      flex-direction: column;
      gap: 2em;     
  }


 
   .about-img-container{
  
    align-items: center;
  } 
  


  .about-main-img {
    width: 22.3em;
  }
  .about-img-1 {
    width: 22.3em;
   
    border-bottom-left-radius: .9em;
  }
  .about-img-2 {
    width: 22.3em;
    
    border-bottom-right-radius: .9em;
  }
  .about-second-img {
   flex-direction: column;
   
  }
}
  
   

