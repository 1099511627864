.clients {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14em;
  width: 100%;
  /* /* padding: 17em 3.4em; */
}
.client-title {
  font-size: 4em;
  font-weight: 400;
  padding-left: 1em;

  /* margin-left: 1em; */
}
.clients-logos {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2em 10em;
}
.client-logo {
  width: 6.5em;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-logo-img {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .clients {
    flex-direction: column;
    gap: 2em;
    margin: 10em 1.5em;
    align-items: flex-start;
  }
  .client-title {
    /* margin-right: 7em; */
    font-size: 2.2em;
    padding-left: 0.2em;
  }
  .clients-logos {
    gap: 1em 4.5em;
  }
  .client-logo {
    width: 4em;
  }
}
